import React from "react";
import near from "../../assets/Events_Assets/near.png";
import pol from "../../assets/Events_Assets/pol.png";

const logos = [
  { src: near, alt: "NEAR" },
  { src: pol, alt: "Polkadot" },
  { src: near, alt: "NEAR" },
  { src: pol, alt: "Polkadot" },
  { src: near, alt: "NEAR" },
  { src: pol, alt: "Polkadot" },
  { src: near, alt: "NEAR" },
  { src: pol, alt: "Polkadot" },
];

const PartnersMarquee = () => {
  return (
    <div className="bg-black py-10 relative overflow-hidden">
      <h2 className="text-center text-pink-500 text-lg mb-6">
        Our Partners and Sponsors
      </h2>
      <div className="relative flex w-full lg:ml-40 overflow-hidden">
        <div className="animate-scroll flex min-w-max gap-6">
          {[...logos, ...logos].map((logo, index) => (
            <div
              key={index}
              className=""
            >
              <img src={logo.src} alt={logo.alt} className="h-20" />
            </div>
          ))}
        </div>
      </div>
      <style>
        {`
          @keyframes scroll {
            from {
              transform: translateX(0);
            }
            to {
              transform: translateX(-50%);
            }
          }

          .animate-scroll {
            display: flex;
            width: max-content;
            animation: scroll 20s linear infinite;
          }
        `}
      </style>
    </div>
  );
};

export default PartnersMarquee;
