import React from "react";
import web from "../assets/Events_Assets/web.png";
import { Calendar1Icon, MapPinPlusInside } from "lucide-react";
import Button from "../components/Button/Button";
import { Link } from "react-router-dom";

const EventPage = () => {
  return (
    <div className="bg-black text-white flex flex-col items-center py-10 px-4">
      <div className="max-w-4xl w-full grid md:grid-cols-2 items-center gap-12">
        <div className="col-span-1  p-4">
          <img src={web} alt="Event Banner" className="w-full rounded-lg" />
        </div>
        <div className="col-span-1 flex flex-col gap-4 text-left">
          <h1 className="text-xl md:text-3xl font-bold text-[#C71585]">
            Dapps over apps conference 2.0
          </h1>
          <div className="mt-4 flex items-center text-gray-300">
            <Calendar1Icon className="mr-2" />
            <p>Sunday, December 15 | 8:00 AM - 6:00 PM</p>
          </div>
          <div className="mt-2 flex items-center text-gray-300">
            <MapPinPlusInside className="mr-2" />
            <p>Cafe one, Yaba, Lagos, Nigeria</p>
          </div>
                  <p className="mt-4">Welcome, click on the link below to register</p>
                  <Link to="/Registration">
          <Button
            bgColor={
                "linear-gradient(90deg, var(--secondary-bg-color) 0%, var(--asset-bg-color) 100%)"
            }
            text="Register now "
            />
            </Link>
        </div>
      </div>

      <div className="mt-16 max-w-4xl w-full ">
        <h2 className="text-lg font-bold text-center">About the event</h2>
        <p className="mt-2 text-gray-300">
          The Civic Center is located in Victoria Island, Lagos Nigeria. It is
          one of the largest and most prestigious Convention Centers in the
          city. It has been the venue for many national and international events
          in the past and offers state-of-the-art event hosting services. It has
          been the venue of all our tech conferences since 2017.
        </p>
        <p className="mt-2 text-gray-300">
          The Civic Center is located in Victoria Island, Lagos Nigeria. It is
          one of the largest and most prestigeous Convention Centers in the
          city. It has been the venue for many national and international events
          in the past and offers state of art event hosting services. It has
          been the venue of all our tech conferences since 2017.The Civic Center
          is located in Victoria Island, Lagos Nigeria. It is one of the largest
          and most prestigeous Convention Centers in the city. It has been the
          venue for many national and international events in the past and
          offers state of art event hosting services. It has been the venue of
          all our tech conferences since 2017.The Civic Center is located in
          Victoria Island, Lagos Nigeria. It is one of the largest and most
          prestigeous Convention Centers in the city. It has been the venue for
          many national and international events in the past and offers state of
          art event hosting services. It has been the venue of all our tech
          conferences since 2017.
        </p>
      </div>
    </div>
  );
};

export default EventPage;
