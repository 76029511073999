import { NavLink } from "react-router-dom";

const HackathonPageCard = ({
  title,
  text1,
  text2,
  date,
  link,
  image,
  pending,
  reverse,
}) => {
  return (
    <div className={`grid md:grid-cols-7 gap-16 items-center`}>
      {/* Conditionally place image first when reverse is true */}
      {reverse ? (
        <>
          <div className="md:col-span-4">
            <img src={image} alt={title} className="rounded-lg" />
          </div>
          <div className="md:col-span-3 flex h-full justify-center items-center bg-grayish p-8 rounded-xl">
            <div>
              <div className="text-primary mb-3">{date}</div>
              <h1 className="text-4xl font-bold py-4">
                {title}
                <span className="text-primary font-extrabold px-3">
                  Hackathon
                </span>{" "}
                Registration
              </h1>
              <div className="pt-3 pb-1 text-lg leading-5">{text1}</div>
              <div className="pb-7 text-lg mb-3 leading-5">{text2}</div>
              <NavLink
                to={link}
                className={
                  pending
                    ? "p-2 primary-gradient rounded px-5 hover:animate-pulse cursor-none"
                    : "p-2 primary-gradient rounded px-5 hover:animate-pulse"
                }
              >
                Register Now
              </NavLink>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="md:col-span-3 flex h-full justify-center items-center bg-grayish p-8 rounded-xl">
            <div>
              <div className="text-primary mb-3">{date}</div>
              <h1 className="text-4xl font-bold py-4">
                {title}
                <span className="text-primary font-extrabold px-3">
                  Hackathon
                </span>{" "}
                Registration
              </h1>
              <div className="pt-3 pb-1 text-lg leading-5">{text1}</div>
              <div className="pb-7 text-lg mb-3 leading-5">{text2}</div>
              <NavLink
                to={link}
                className={
                  pending
                    ? "p-2 primary-gradient rounded px-5 hover:animate-pulse cursor-none"
                    : "p-2 primary-gradient rounded px-5 hover:animate-pulse"
                }
              >
                Register Now
              </NavLink>
            </div>
          </div>
          <div className="md:col-span-4">
            <img src={image} alt={title} className="w-full rounded-lg" />
          </div>
        </>
      )}
    </div>
  );
};

export default HackathonPageCard;
