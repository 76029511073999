import React from "react";
import style from "../styles/Portfolio.module.css";
import ellipse_1 from "../assets/Portfolio_Assets/Ellipse 393.png";
import circle_1 from "../assets/Portfolio_Assets/Ellipse 390.png";
import circle_2 from "../assets/Portfolio_Assets/Ellipse 391.png";
import ellipse_2 from "../assets/Portfolio_Assets/Vector 3.png";
import ellipse_3 from "../assets/Portfolio_Assets/Ellipse 392.png";
import rectangle4309 from "../assets/Portfolio_Assets/Rectangle 4309.png";
import yaba from "../assets/Portfolio_Assets/yaba.png";
import Page from "../components/Page";
import PortfolioCard from "./PortfolioCard";


const Portfolio = () => {
  return (
    <Page>
      <div className={style.vector__images}>
        <img src={ellipse_1} alt="" className={style.ellipse_1} />
        <img src={circle_1} alt="" className={style.circle_1} />
        <img src={circle_2} alt="" className={style.circle_2} />
        <img src={ellipse_2} alt="" className={style.ellipse_2} />
        <img src={ellipse_2} alt="" className={style.ellipse_2} />
        <img src={ellipse_3} alt="" className={style.ellipse_3} />

        <div className={style.vector__images__text}>
          <h1>
            <span className={style.pink__span}>dApps over apps</span>{" "}
            contribution to the{" "}
            <span className={style.purple__span}>growth</span>
            <br /> of the <span className={style.cream__span}>Web 3</span>
          </h1>
          <p>Our Portfolio and Projects</p>
        </div>
      </div>
      <div className="my-10 flex flex-col space-y-16">
        <PortfolioCard
          title="Dapps over apps conference Unilag 2024"
          text1="Stand out in a crowded market! Blockchain events offer unparalleled visibility, putting your brand in front of industry leaders, innovators, and enthusiasts."
          text2="Blockchain events attract top talent from across the industry, making them an ideal recruiting ground for companies looking to expand their teams. Sponsors can use events to showcase their company culture, values, and career opportunities, attracting qualified candidates and building a pipeline of talent."
          imageSrc={rectangle4309}
          buttonText="View Event"
          link="portfolio1"
          reverse={false}
        />

        <PortfolioCard
          title="Open Web Tour, Lasu"
          text1="Stand out in a crowded market! Blockchain events offer unparalleled visibility, putting your brand in front of industry leaders, innovators, and enthusiasts."
          text2="Blockchain events attract top talent from across the industry, making them an ideal recruiting ground for companies looking to expand their teams. Sponsors can use events to showcase their company culture, values, and career opportunities, attracting qualified candidates and building a pipeline of talent."
          imageSrc={rectangle4309}
          buttonText="View Event"
          link="portfolio1"
          reverse={true}
        />

        <PortfolioCard
          title="Open Web Tour, Cafe One, Yaba"
          text1="Stand out in a crowded market! Blockchain events offer unparalleled visibility, putting your brand in front of industry leaders, innovators, and enthusiasts."
          text2="Blockchain events attract top talent from across the industry, making them an ideal recruiting ground for companies looking to expand their teams. Sponsors can use events to showcase their company culture, values, and career opportunities, attracting qualified candidates and building a pipeline of talent."
          imageSrc={yaba}
          link="portfolio1"
          buttonText="View Event"
          reverse={false}
        />
      </div>
    </Page>
  );
};

export default Portfolio;
