import React from "react";
import Countdown from "react-countdown";
import hero from "../assets/hero.png";
import Button from "../components/Button/Button";
import { Link } from "react-router-dom";

const EventHero = () => {
  const eventDate = new Date("2025-03-28T09:00:00").getTime();

  return (
    <div className="relative w-full h-screen bg-black">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${hero})` }}
      ></div>

      {/* Content */}
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-white text-center px-6">
        <p className="text-lg text-pink-600 px-4 py-2 font-medium">
          June 28, 2025. 9:00 AM
        </p>
        <h1 className="text-3xl md:text-5xl font-bold mt-4">
          Dapps over apps conference Unilag <br />
          <span className="text-white">2025</span>
        </h1>
        <p className="mt-4 max-w-2xl text-gray-200">
          Join the dapps over apps community and propel your career in the
          blockchain ecosystem. Go from noob to expert, join the community of
          web/app developers, creatives, NFT traders, and more.
        </p>

        {/* Countdown Timer */}
        <div className="flex space-x-4 mt-6 text-xl font-bold">
          <Countdown
            date={eventDate}
            renderer={({ days, hours, minutes, seconds }) => (
              <div className="flex space-x-4">
                {[
                  { label: "Days", value: days },
                  { label: "Hours", value: hours },
                  { label: "Minutes", value: minutes },
                  { label: "Seconds", value: seconds },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="p-4 relative shadow-lg rounded-lg backdrop-blur-none  border-transparent"
                    style={{
                      background:
                        "linear-gradient(to right, rgba(255,0,0,0.2), rgba(128,0,128,0.2))",
                    }}
                  >
                    <span className="block text-3xl">{item.value}</span>{" "}
                    {item.label}
                  </div>
                ))}
              </div>
            )}
          />
        </div>

        {/* Buttons */}
        <div className="mt-10 flex  gap-8">
          <button className="px-4 py-2 border border-[#C71585] rounded-lg">
            Be a Sponsor
          </button>
          <Link to="/EventPage">
          <Button
            bgColor={
              "linear-gradient(90deg, var(--secondary-bg-color) 0%, var(--asset-bg-color) 100%)"
            }
            text="Get tickets "
            />
            </Link>
        </div>
      </div>
    </div>
  );
};

export default EventHero;
