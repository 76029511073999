import React from "react";
import Page from "../components/Page";
import EventHero from "./EventHero";
import ConferenceSection from "../components/Events/ConferenceSection";
import EventSection from "../components/Events/EventsSection";
import MeetSpeakers from "../components/Events/Speakers";
import DappsConference from "../components/Events/Conference";
import PartnersMarquee from "../components/Events/PartnersMarquee";

const Events = () => {
	return (
		<Page>
			<EventHero />
			<PartnersMarquee/>
				<ConferenceSection />
				<EventSection />
				<MeetSpeakers />
				<DappsConference/>
		</Page>
	);
};

export default Events;
