import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const PortfolioCard = ({
  title,
  text1,
  text2,
  imageSrc,
  imageAlt,
  link,
  buttonText,
  reverse,
}) => {
  return (
    <div className="relative w-full p-6 bg-black text-white rounded-xl">
      {/* Gradient Border Effect */}
      <div className="absolute inset-0 rounded-xl border border-transparent bg-gradient-to-t from-purple-700 to-red-500"></div>

      <div className="relative p-6 grid md:grid-cols-2 gap-24 -m-[1.45rem] bg-black rounded-xl">
        {/* Image */}
        <div className={`col-span-1 ${reverse ? "md:order-last" : ""}`}>
          <img
            src={imageSrc}
            alt={imageAlt}
            className="rounded-lg w-full h-auto"
          />
        </div>

        {/* Content */}
        <div className="flex justify-center items-center md:w-[80%] col-span-1">
          <div className="flex flex-col gap-4">
            <h2 className="text-2xl font-bold">{title}</h2>
            <div className="pt-3 pb-1 text-base font-normal leading-6">{text1}</div>
            <div className=" text-base mb-3 font-normal leading-6">{text2}</div>
            <NavLink
              to={link}
              className="p-2 w-fit primary-gradient rounded px-5 hover:animate-pulse"
            >
              {buttonText}
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

PortfolioCard.propTypes = {
  title: PropTypes.string.isRequired,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  link: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
};

PortfolioCard.defaultProps = {
  imageAlt: "Portfolio Image",
  reverse: false,
};

export default PortfolioCard;
