import React from "react";
import bolaji from "../../assets/Events_Assets/bolaji.png";

const speakers = [
  {
    name: "Bolaji Ahmad",
    role: "Software developer at Polkadot",
    image: bolaji,
},
{
    name: "Bolaji Ahmad",
    role: "Software developer at Polkadot",
    image: bolaji,
},
{
    name: "Bolaji Ahmad",
    role: "Software developer at Polkadot",
    image: bolaji,
},
{
    name: "Bolaji Ahmad",
    role: "Software developer at Polkadot",
    image: bolaji,
},
{
    name: "Bolaji Ahmad",
    role: "Software developer at Polkadot",
    image: bolaji,
},
{
    name: "Bolaji Ahmad",
    role: "Software developer at Polkadot",
    image: bolaji,
  },
];

const MeetSpeakers = () => {
  return (
    <section className="py-12 bg-black text-white">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">
          Meet the Speakers
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {speakers.map((speaker, index) => (
            <div
              key={index}
              className="relative group overflow-hidden rounded-lg"
            >
              <img
                src={speaker.image}
                alt={speaker.name}
                className="w-full h-64 object-cover rounded-lg group-hover:scale-105 transition-transform duration-300"
              />
              <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-r from-black to-white opacity-70 backdrop-blur-sm">
                <div className="relative">
                  <h3 className="text-lg font-bold text-white">
                    {speaker.name}
                  </h3>
                  <p className="text-sm text-gray-100">{speaker.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MeetSpeakers;
