import React from "react";
import people from "../../assets/Events_Assets/people.png";
import networking from "../../assets/Events_Assets/networking.png";
import hackathon from "../../assets/Events_Assets/hackathon.png";
import workshop from "../../assets/Events_Assets/workshop.png";
import speakers from "../../assets/Events_Assets/speakers.png";
import hours from "../../assets/Events_Assets/hours.png";
import innovation from "../../assets/Events_Assets/innovation.png";

const EventSection = () => {
  return (
    <div className="bg-black text-white py-12 px-6 md:mx-20 text-center">
      <button className="bg-[#551A8B] border border-[#551A8B66] bg-opacity-40 text-[#C71585] py-2 px-4 rounded-full mb-4">
        Why should we see you there?
      </button>
      <h2 className="text-2xl font-bold mb-6">
        Great reasons to attend dApps over apps events.
      </h2>
      <div className="grid md:grid-cols-3 md:grid-rows-3 gap-4">
        <div className="relative group h-fit overflow-hidden rounded-lg md:row-span-2">
          <img
            src={people}
            alt="2000+ Expected Attendees"
            className="w-full object-cover transition-transform duration-300 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-4">
            <h3 className="text-xl md:text-2xl font-bold">2000+</h3>
            <p className="text-sm">Expected Attendees</p>
          </div>
        </div>
        <div className="relative group row-span-1 h-fit overflow-hidden rounded-lg">
          <img
            src={networking}
            alt="Networking"
            className="w-full object-cover transition-transform duration-300 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-4">
            <h3 className="text-xl md:text-2xl font-bold">Networking</h3>
            <p className="text-sm">Connect with global blockchain leaders</p>
          </div>
        </div>
        <div className="relative group h-fit row-span-1 overflow-hidden rounded-lg">
          <img
            src={hackathon}
            alt="Hackathon"
            className="w-full object-cover transition-transform duration-300 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-4">
            <h3 className="text-xl md:text-2xl font-bold">Hackathon</h3>
            <p className="text-sm">Hands-on technology experiences</p>
          </div>
        </div>
        <div className="relative group h-fit row-span-1 overflow-hidden rounded-lg">
          <img
            src={workshop}
            alt="Workshops"
            className="w-full object-cover transition-transform duration-300 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-4">
            <h3 className="text-xl md:text-2xl font-bold">Workshops</h3>
            <p className="text-sm">
              Interactive sessions with industry experts
            </p>
          </div>
        </div>

        <div className="relative group h-fit md:row-span-2 overflow-hidden rounded-lg ">
          <img
            src={innovation}
            alt="Innovation"
            className="w-full object-cover transition-transform duration-300 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-4">
            <h3 className="text-xl md:text-2xl font-bold">Innovation</h3>
            <p className="text-sm">Showcase of cutting-edge projects</p>
          </div>
        </div>
        <div className="relative group row-span-1 h-fit overflow-hidden rounded-lg">
          <img
            src={speakers}
            alt="Industry Speakers"
            className="w-full object-cover transition-transform duration-300 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-4">
            <h3 className="text-xl md:text-2xl font-bold">20+</h3>
            <p className="text-sm">Industry Speakers</p>
          </div>
        </div>
        <div className="relative group row-span-1 h-fit overflow-hidden rounded-lg">
          <img
            src={hours}
            alt="Hours of Innovation"
            className="w-full object-cover transition-transform duration-300 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-4">
            <h3 className="text-xl md:text-2xl font-bold">12</h3>
            <p className="text-sm">Hours of Innovation</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSection;
