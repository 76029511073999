import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import yaba from "../assets/Portfolio_Assets/yaba.png";
import rectangle4309 from "../assets/Portfolio_Assets/Rectangle 4309.png";
import rectangle4310 from "../assets/Portfolio_Assets/Rectangle 4310.png";
import rectangle4314 from "../assets/Portfolio_Assets/Rectangle 4314.png";

const projects = [
  { id: 1, title: "Open Web Tour, Ikeja", image: rectangle4309 },
  { id: 2, title: "Open Web Tour, LASU", image: rectangle4314 },
  { id: 3, title: "Open Web Tour, UNILAG", image: yaba },
  { id: 4, title: "Open Web Tour, FUTA", image: rectangle4310 },
];

export default function Portfolio1() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const itemsPerView = window.innerWidth < 640 ? 1 : 3; // 1 item on mobile, 3 on large screens

  const prevSlide = () => {
    setCurrentIndex((prev) =>
      prev === 0 ? projects.length - itemsPerView : prev - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prev) =>
      prev >= projects.length - itemsPerView ? 0 : prev + 1
    );
  };

  return (
    <div className="bg-black text-white py-10 px-5 md:px-20">
      {/* Other Projects Carousel */}
      <div className="relative w-full  ">
        <img
          src={rectangle4309}
          alt="Conference"
          className="w-full  object-contain rounded-lg"
        />
      </div>
      <h2 className="text-2xl md:text-3xl font-bold mt-6">
        Dapps over apps conference Unilag 2024
      </h2>
      <p className="text-gray-300 mt-4 ">
        Looking Back at the DApps Over Apps Conference
      </p>
      <p className="text-gray-300 leading-6 mt-4 md:w-[70%]">
        The DApps Over Apps Conference brought together innovators, developers,
        and enthusiasts from around the world to explore the transformative
        power of decentralized applications. Hosted by a community passionate
        about the future of Web3, the conference served as a platform for
        knowledge-sharing, collaboration, and inspiration.
      </p>

      {/* Key Highlights */}
      <div className="mt-8 w-full md:w-[70%]">
        <h3 className="text-xl">Key Highlights</h3>
        <ol className="flex flex-col space-y-3 mt-3 list-decimal pl-5">
          <li>
            <span className="font-semibold text-lg">
              Insightful Keynote Sessions
            </span>
            <ul className="list-disc text-base pl-4 mt-1 space-y-1">
              <li>
                Industry leaders shared their vision of a decentralized future,
                with talks on topics ranging from blockchain scalability to the
                integration of DApps into mainstream industries.
              </li>
              <li>
                Standout sessions included discussions on “The Future of
                Financial Decentralization” and “Bridging the Gap Between Web2
                and Web3.”
              </li>
            </ul>
          </li>
          <li>
            <span className="font-semibold text-lg">Hands-on Workshops</span>
            <ul className="list-disc flex flex-col text-base pl-4 mt-1 space-y-1">
              <li>Exploring smart contract development</li>
              <li>Advanced blockchain tooling</li>
            </ul>
          </li>

          <li>
            <span className="font-semibold text-lg">Hackathon</span>
            <ul className="list-disc  pl-6 mt-1 space-y-1">
              <li>
                The hackathon challenged participants to create innovative DApps
                within 48 hours. Teams showcased groundbreaking ideas addressing
                real-world problems, with winning projects focusing on DeFi,
                NFTs, and decentralized identity solutions.
              </li>
            </ul>
          </li>
          <li>
            <span className="font-semibold text-lg">Panel Discussions</span>
            <ul className="list-disc flex flex-col pl-6 mt-1 space-y-1">
              <li>
                Panels featured experts discussing critical challenges in DApp
                adoption, such as UX improvements, scalability, and regulatory
                landscapes.
              </li>
              <li>
                Panelists included developers, policymakers, and investors,
                offering diverse perspectives.
              </li>
            </ul>
          </li>
          <li>
            <span className="font-semibold text-lg">
              Networking Opportunities
            </span>
            <ul className="list-disc pl-6 mt-1 space-y-1">
              <li>
                The conference fostered connections through informal meetups,
                breakout sessions, and networking mixers. Attendees had the
                chance to collaborate with like-minded peers and potential
                partners.
              </li>
            </ul>
          </li>
          <li>
            <span className="font-semibold text-lg">
              Showcase of Innovations
            </span>
            <ul className="list-disc pl-6 mt-1 space-y-1">
              <li>
                Startups and established companies showcased their latest DApps
                and blockchain solutions in the exhibition hall, sparking
                interest and excitement about the possibilities of
                decentralization.
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <div className="mt-10 w-full  md:w-[80%]">
        <h3 className="font-semibold text-lg">
          A Community United by Decentralization
        </h3>
        <p className="text-base leading-6 mb-1">
          The DApps Over Apps Conference wasn’t just an event—it was a
          celebration of a shared vision to build a decentralized, equitable,
          and secure digital future. By fostering dialogue, collaboration, and
          learning, the conference left attendees inspired to contribute to the
          Web3 ecosystem.
        </p>
        <p className="text-base leading-5">
          As we look forward to the next edition, we’re excited to continue
          exploring the limitless potential of decentralized applications. Stay
          tuned for more updates and opportunities to be part of this incredible
          journey!
        </p>
      </div>

      <div className="mt-12 flex justify-between items-center">
        <h3 className="text-xl md:text-3xl font-semibold">Other Projects</h3>
        <div className="flex items-center space-x-4">
          <button
            onClick={prevSlide}
            className="p-2 bg-black shadow-xl rounded-md border border-gray-100 hover:scale-90"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          <button
            onClick={nextSlide}
            className="p-2 bg-black shadow-xl rounded-md border border-gray-100 hover:scale-90"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Image Slider */}
      <div className="w-full overflow-hidden mt-4">
        <div
          className="flex md:gap-12 transition-transform duration-300"
          style={{
            transform: `translateX(-${currentIndex * (100 / itemsPerView)}%)`,
          }}
        >
          {projects.map((project) => (
            <div
              key={project.id}
              className="w-full sm:w-1/3 flex-shrink-0 flex flex-col items-start"
            >
              <div className="relative w-full h-96">
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="mt-2 text-lg md:text-3xl font-semibold text-start">
                {project.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
