import React from "react";

const ConferenceSection = () => {
  return (
    <section className="bg-gradient-to-b from-black via-gray-900 to-black text-white py-16 text-center px-4">
      <button className="bg-[#551A8B] border border-[#551A8B66] bg-opacity-40 text-[#C71585] px-6 py-2 rounded-full mb-4 transition">
        Discover the experience
      </button>
      <h2 className="text-3xl md:text-4xl font-bold">
        Dapps over apps conference Unilag
      </h2>
      <h3 className="text-2xl font-semibold mt-2">2025</h3>
      <p className="max-w-3xl mx-auto mt-6 text-gray-300">
        Stand out in a crowded market!{" "}
        <span className="text-[#C71585]">Blockchain events</span> offer
        unparalleled visibility, putting your brand in front of industry
        leaders, innovators, and enthusiasts.
      </p>
      <p className="max-w-3xl mx-auto mt-4 text-gray-300">
        <span className="text-[#C71585]">Blockchain events</span> attract top
        talent from across the industry, making them an ideal recruiting ground
        for companies looking to expand their teams. Sponsors can use events to
        showcase their company culture, values, and career opportunities,
        attracting qualified candidates and building a pipeline of talent.
      </p>
      <p className="max-w-3xl mx-auto mt-4 text-gray-300">
        Build brand recognition and credibility. By hosting or participating in{" "}
        <span className="text-[#C71585]">blockchain events</span>, you
        demonstrate your commitment to innovation, fostering trust and loyalty
        among your audience.
      </p>
    </section>
  );
};

export default ConferenceSection;
