import Page from "../components/Page";
import HackathonPageCard from "../components/HackathonPageCard";
import OpenWebImg from "../assets/Hackathon_Assets/OpenWebImg.png";
import arbitrum from "../assets/Hackathon_Assets/Arbitrum.png";
import solana from "../assets/Hackathon_Assets/Solana.png";
import shutter from "../assets/Hackathon_Assets/shutter.png";


const HackathonPage = () => {
  return (
    <Page className="text-white">
      <h1 className="text-3xl mb-2 font-bold rounded-2xl primary-gradient p-2 px-5 w-fit mt-14 animate-bounce">
        Open Hackathons
      </h1>

      <div className="space-y-10">
        <HackathonPageCard
          title={"Open Web Tour"}
          text1={
            <div>
              Showcase your innovation using Arbitrum Stylus and SDK. Win{" "}
              <span className="text-xl font-bold">#3 Million</span> and access
              to a global network to scale your products.
            </div>
          }
          text2={"Register now and start building!"}
          date={"Now - September 15th, 2024"}
          link={"/login"}
          image={OpenWebImg}
        />

        <HackathonPageCard
          title={"Open Web Tour"}
          text1={
            "Register for the Open Web Tour Hackathon and stand a chance to win a hackathon bounty of $2,000."
          }
          text2={
            "Open Web Tour is where you Learn, Earn and Build on the Near Blockchain using Rust programming language"
          }
          date={"June 3rd - July 24th, 2024"}
          link={"/login"}
          image={arbitrum}
          reverse={true}
        />

        <div className="mt-6 space-y-10">
          <h1 className="text-3xl -mb-4 font-bold rounded-2xl primary-gradient p-2 px-5 w-fit mt-14 animate-bounce">
            Closed Hackathons
          </h1>
          <HackathonPageCard
            title={"Open Web Tour"}
            text1={
              "Register for the Open Web Tour Hackathon and stand a chance to win a hackathon bounty of $2,000."
            }
            text2={
              "Open Web Tour is where you Learn, Earn and Build on the Near Blockchain using Rust programming language"
            }
            date={"June 3rd - July 24th, 2024"}
            link={"/login"}
            image={shutter}
          />

          <HackathonPageCard
            title={"Open Web Tour"}
            text1={
              "Register for the Open Web Tour Hackathon and stand a chance to win a hackathon bounty of $2,000."
            }
            text2={
              "Open Web Tour is where you Learn, Earn and Build on the Near Blockchain using Rust programming language"
            }
            date={"June 3rd - July 24th, 2024"}
            link={"/login"}
            image={solana}
            reverse={true}
          />
        </div>
      </div>
    </Page>
  );
};

export default HackathonPage;
