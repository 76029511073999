import { useState } from "react";
import Button from "../components/Button/Button";

const BASE_URL = "https://coherent-anna-maria-ifedolapo.koyeb.app/api";

const FormWithProgress = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    phoneNo: "",
    experience: "",
    familiarity: "",
    institution: "",
    publicity: "",
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  // Required fields for submission
  const requiredFields = [
    "firstName",
    "lastName",
    "email",
    "role",
    "institution",
  ];
  const isFormComplete = requiredFields.every(
    (field) => formData[field].trim() !== ""
  );

  // Calculate progress
  const totalFields = Object.keys(formData).length;
  const filledFields = Object.values(formData).filter(Boolean).length;
  const progress = Math.round((filledFields / totalFields) * 100);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormComplete) {
      setMessage({ type: "error", text: "Please fill all required fields." });
      return;
    }

    setLoading(true);
    setMessage(null);

    try {
      const response = await fetch(`${BASE_URL}/event-registration`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Something went wrong!");
      }

      setMessage({ type: "success", text: "🎉 Registration successful!" });

      // Reset form
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        phoneNo: "",
        experience: "",
        familiarity: "",
        institution: "",
        publicity: "",
      });
    } catch (error) {
      setMessage({ type: "error", text: error.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="gap-12 p-6 bg-black max-w-6xl grid md:grid-cols-3 md:mx-28 text-white"
    >
      <div className="bg-[#0E0E0E] p-10 rounded-lg md:col-span-2">
        <div className="grid  md:grid-cols-2 gap-4">
          <div className="md:col-span-1">
            <label className="text-sm">
              First Name<span className="text-red-500"> *</span>
            </label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="Ifedimeji"
              className="p-2 rounded border mt-2 bg-transparent border-gray-200 w-full text-white placeholder-gray-400 focus:outline-none"
            />
          </div>
          <div className="md:col-span-1">
            <label className="text-sm">
              Last Name<span className="text-red-500"> *</span>
            </label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Omoniyi"
              className="p-2 rounded border mt-2 bg-transparent border-gray-200 w-full text-white placeholder-gray-400 focus:outline-none"
            />
          </div>
          <div className="col-span-1 md:col-span-2">
            <label className="text-sm">
              Email Address<span className="text-red-500"> *</span>
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="omoniyifedimeji@gmail.com"
              className="p-2 rounded border mt-2 bg-transparent border-gray-200 w-full text-white placeholder-gray-400 focus:outline-none"
            />
          </div>
          <div className="col-span-1 md:col-span-2">
            <label className="text-sm">
              Role<span className="text-red-500"> *</span>
            </label>
            <select
              name="role"
              value={formData.role}
              onChange={handleChange}
              className="p-2 rounded border mt-2 bg-[#1A1A1A] border-gray-200 w-full text-white placeholder-gray-400 focus:outline-none"
            >
              <option value="">Select your role</option>
              <option value="Frontend Developer">Frontend Developer</option>
              <option value="Backend Developer">Backend Developer</option>
              <option value="Full Stack Developer">Full Stack Developer</option>
            </select>
          </div>

          {[
            {
              label: "Phone Number",
              name: "phoneNo",
              placeholder: "08029374619",
            },
            {
              label: "Years of Experience",
              name: "experience",
              placeholder: "3 years",
            },
            {
              label: "Familiarity with Blockchain/Web3",
              name: "familiarity",
              placeholder: "Very Familiar",
            },
            {
              label: "Educational Institution",
              name: "institution",
              required: true,
              placeholder: "Unilag",
            },
          ].map(({ label, name, required, placeholder }) => (
            <div key={name} className="col-span-1 md:col-span-1">
              <label className="text-sm">
                {label}
                {required && <span className="text-red-500"> *</span>}
              </label>
              <input
                type="text"
                name={name}
                value={formData[name]}
                onChange={handleChange}
                placeholder={placeholder}
                className="p-2 rounded border mt-2 bg-transparent border-gray-200 w-full text-white placeholder-gray-400 focus:outline-none"
              />
            </div>
          ))}

          <div className="col-span-1 md:col-span-2">
            <label className="text-sm">How did you hear about the event</label>
            <input
              type="text"
              name="publicity"
              value={formData.publicity}
              onChange={handleChange}
              placeholder="On Twitter"
              className="p-2 rounded mt-2 border bg-transparent border-gray-200 w-full text-white placeholder-gray-400 focus:outline-none"
            />
          </div>
        </div>
      </div>
      <div className="bg-[#0e0e0e] p-6 rounded-lg col-span-2 md:col-span-1 h-fit flex flex-col gap-4 items-center">
        <p className="text-lg">Application filled</p>
        <h2 className="text-3xl font-bold">{progress}%</h2>
        <div className="col-span-1 md:col-span-2 flex justify-center mt-4">
          <Button
            type="submit"
            bgColor="linear-gradient(90deg, var(--secondary-bg-color) 0%, var(--asset-bg-color) 100%)"
            text={loading ? "Submitting..." : "Submit Registration"}
            className={
              isFormComplete && !loading ? "" : "opacity-50 cursor-not-allowed"
            }
            disabled={!isFormComplete || loading}
          />
        </div>
        {message && (
          <div
            className={`text-${
              message.type === "error" ? "red" : "green"
            }-500 mt-2 text-center`}
          >
            {message.text}
          </div>
        )}
      </div>
    </form>
  );
};

export default FormWithProgress;
