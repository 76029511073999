import React from "react";
import people from "../../assets/Events_Assets/people.png";
import networking from "../../assets/Events_Assets/networking.png";
import hackathon from "../../assets/Events_Assets/hackathon.png";
import bolaji from "../../assets/Events_Assets/bolaji.png";
import Button from "../Button/Button";

const DappsConference = () => {
  return (
    <div className=" text-white max-w-5xl my-20 mx-auto flex flex-col md:flex-row gap-6">
      {/* Images Section */}
      <div className="grid md:grid-cols-2 gap-20 w-full">
        <div className="col-span-1 grid md:grid-cols-2 gap-8">
          <div className="grid grid-rows-2 gap-3 w-full">
            <img
              src={people}
              alt="Conference 1"
              className="rounded-lg w-full h-48 object-cover"
            />
            <img
              src={networking}
              alt="Conference 2"
              className="rounded-lg w-full md:-mt-6 h-48 object-cover"
            />
          </div>
          <div className="grid grid-rows-2 md:mt-20  gap-8 w-full">
            <img
              src={hackathon}
              alt="Conference 3"
              className="rounded-lg w-full h-48 object-cover"
            />
            <img
              src={bolaji}
              alt="Conference 4"
              className="rounded-lg w-full h-48 object-cover"
            />
          </div>
        </div>

        {/* Text Content */}
        <div className="flex flex-col gap-6 md:mt-20 w-full ">
          <h2 className="text-2xl md:text-3xl font-bold">
            Dapps over apps conference Unilag 2025
          </h2>
          <p className="mt-3 text-gray-300">
            Join us for an immersive experience in blockchain technology,
            networking with industry leaders, and shaping the future of
            decentralized systems. Don’t miss this opportunity to be at the
            forefront of innovation!
          </p>

          {/* Buttons */}
          <div className="mt-4 flex  gap-8">
            <button className="px-4 py-2 border border-[#C71585] rounded-lg">
              Be a Sponsor
            </button>
            <Button
              bgColor={
                "linear-gradient(90deg, var(--secondary-bg-color) 0%, var(--asset-bg-color) 100%)"
              }
              text="Get tickets "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DappsConference;
